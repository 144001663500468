// This file was automatically generated from admin.venue.photos.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenuePhotosEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenuePhotosEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenuePhotosEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-edit"><div class="images-upload-area"><div><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/edit">&lt;&lt; back to venue edit</a></div><h3>', soy.$$escapeHtml(opt_data.content.venue.name), ' Images for frontend</h3><div>');
  if (opt_data.content.venue.photos) {
    var photoList6587 = opt_data.content.venue.photos;
    var photoListLen6587 = photoList6587.length;
    for (var photoIndex6587 = 0; photoIndex6587 < photoListLen6587; photoIndex6587++) {
      var photoData6587 = photoList6587[photoIndex6587];
      output.append('<div class="float-left"><img src="/.h/download/', soy.$$escapeHtml(photoData6587.medium), '" /><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/delete-photo/', soy.$$escapeHtml(photoIndex6587), '">[x]</a></div>');
    }
  } else {
    output.append('None uploaded.');
  }
  output.append('<div class="float-end"></div></div><div><form enctype="multipart/form-data" action="', soy.$$escapeHtml(opt_data.content.upload_form_url), '" method="post"><p>Upload new photo <br/><input type="file" name="photo"/></p><input type="submit" value="Submit"></form></div></div></div>');
  return opt_sb ? '' : output.toString();
};
